/** @jsx jsx */ //Note From John: this has to be at the top of the page for sx to work properly
import { graphql } from "gatsby";
import Layout from "../components/layout/layout.js";
import { jsx } from "theme-ui";

export default function MyFiles({ data }) {
  return (
    <Layout>
      <main>
        <h1 
          sx={{
            textAlign: ['center', 'center','left','left'],
            color: '#699ec9;',
            fontWeight: ['150','300'],
            fontSize: ['150%','200%',],
            width: ['100%', '100%', '75%',],
          }}
        >{data.markdownRemark.frontmatter.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
      </main>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`