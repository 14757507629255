/** @jsx jsx */ //Note From John: this has to be at the top of the page for sx to work properly
import { jsx } from "theme-ui";

export default function Footer({ children }) {
    const now = new Date();
    const year = now.getFullYear();
    
    return ( 
        <footer
            sx={{
                background: '#415462',
                fontFamily: 'body',
                height: ['15px','15px','30px'],
                mx: ['0px','30px','50px'],
                py: '30px',
                pt: ['15px', '15px', '30px'],
                position: 'fixed',
                bottom: '0px',
                width: ['100%','90%'],
            }}
        > 
            <div
                sx={{
                    color: '#a9a305',
                    float: ['none','none','left'],
                    pl: ['none','none','100px'],
                    textAlign:['center','center','left'],
                    pr: ['none','none','200px'],
                    pb:['.5rem','.5rem','none']
                }}
            >
            IDEAS THAT DRIVE PERFORMANCE
            </div>
            <div
                sx={{
                    color: '#ffffff',
                    fontSize: '12px',
                    m:'0',
                    p:'0',
                    pr: ['none','none','50px'],
                    textAlign:['center','center','right'],
                }}
            >
            &copy;Copyright {year}, Brandlogic&reg; All rights reserved.
            </div>
        </footer>
    );
}
