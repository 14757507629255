/** @jsx jsx */ //Note From John: this has to be at the top of the page for sx to work properly
import { Link } from 'gatsby';
import { jsx } from "theme-ui";
import PrimaryNav from '../primary-nav/primary-nav';
import MetaNav from '../meta-nav/meta-nav';
import { StaticImage } from 'gatsby-plugin-image';
import config from '../../../site-config';

export default function Header({ children }) {
    return ( 
        <header>
            <Link to = "/" > 
            <StaticImage src = '../../../static/images/logo.svg'
                alt = 'Brandlogic logo'
                placeholder = 'tracedSVG'
                sx = {{ 
                    width: '325px',
                    ml: ['20px', '20px','50px'],
                    mt: '20px',
                }}/> 
            </Link> {
                config.siteTitle && config.showSiteTitle && < span > { config.siteTitle } </span>} 
            <PrimaryNav/>
            <MetaNav/>
        </header>
    );
}