module.exports = {
    siteTitle: 'Brandlogic',
    showSiteTitle: false, //set this to true if you want the site title to show in the header component
    siteTitleShort: 'sitetitle',
    siteDescription: 'Description goes here',
    siteUrl: 'https://brandlogic.com',
    // fontLibrary: 'Add URL to fontLibrary here',
    // Prefixes all links. For cases when deployed to example.github.io/gatsby-advanced-starter/.
    pathPrefix: null,
    logo: '/images/logo.svg',
    social: {
        twitter: '',
        fbAppId: '',
    },
    company: {
        type: '',
        additionalType: '',
        name: '',
        legalName: '',
        alternateNames: [''],
        email: '',
        address: {
            country: 'United States',
            region: '',
            postalCode: '',
            streetAddress: '',
        },
        sameAs: [
            '',
        ],
        telephone: [
            '',
        ],
    },
};